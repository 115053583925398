import React from "react"
import queryString from "query-string"
import { injectIntl } from "gatsby-plugin-intl"
import { Spin, Col, Row, Button } from "antd"

import Template from "../../layouts/base"
import error from "../../assets/images/ingests/error.gif"
import success from "../../assets/images/ingests/success.gif"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import LocalizedAniLink from "../../components/localizedAniLink"
import withAuth from "../../components/withAuthHoc"

class IngestCreated extends React.Component {
  constructor(props) {
    super(props)

    const _thatIntl = this.props.intl

    this.ingestNamesTypes = {
      REAL_TIME: _thatIntl.formatMessage({ id: "new-ingestion-rt-title" }),
      NEAR_REAL_TIME: _thatIntl.formatMessage({
        id: "new-ingestion-nrt-title",
      }),
      BATCH: _thatIntl.formatMessage({ id: "new-ingestion-batch-title" }),
      METADATA: _thatIntl.formatMessage({ id: "new-ingestion-metadata-title" }),
    }

    const args = queryString.parse(props.location.search)
    this.codeIngestType = args.t

    this.state = {
      status: "LOADING",
      result: args.status,
      id: args.id,
    }
  }

  componentDidMount() {
    this.setState({
      status: this.state.result,
    })
  }

  render() {
    let toUrl = `/ingest/download?id=${this.state.id}&t=${this.codeIngestType}`

    // if (
    //   typeof window !== "undefined" &&
    //   window.location &&
    //   window.location.search
    // ) {
    //   const urlParams = new URLSearchParams(window.location.search)
    //   const accumulator = urlParams.get("accumulator")

    //   toUrl =
    //     accumulator && accumulator === "true"
    //       ? `${toUrl}&accumulator=true`
    //       : toUrl
    // }

    const _thatIntl = this.props.intl

    return (
      <Template selected={["ingest", "ingest-new"]}>
        <CustomBreadcrumb
          crumbs={[
            {
              label: _thatIntl.formatMessage({
                id: "menu-ingestions-management",
              }),
              link: "/ingest",
            },
            _thatIntl.formatMessage({
              id: "new-ingestion-breadcrumb-creating",
            }),
            `${this.ingestNamesTypes[this.codeIngestType]}`,
          ]}
        />

        <Row>
          <Col className="intro">
            <h1>
              {_thatIntl.formatMessage({ id: "new-ingestion-result-header" })}
            </h1>
          </Col>
        </Row>

        <div className="content">
          <Row type="flex" justify="center" align="top">
            {/* Initial Status Loading /////////////////////////////////////////////////////////////////////////// */}
            {this.state.status === "LOADING" && (
              <>
                <Col span={12} style={{ padding: "10em" }}>
                  <Spin size="large" />
                  {_thatIntl.formatMessage({
                    id: "new-ingestion-result-loading-status",
                  })}
                </Col>
              </>
            )}

            {/* Status KO  /////////////////////////////////////////////////////////////////////////////////////// */}
            {this.state.status === "INGEST_STATUS_KO" && (
              <div>
                <Col span={18} offset={4}>
                  <h1>
                    {_thatIntl.formatMessage({
                      id: "new-ingestion-result-ko-status-header",
                    })}
                  </h1>
                  <p>
                    {_thatIntl.formatMessage({
                      id: "new-ingestion-result-ko-status-subheader",
                    })}
                  </p>
                  <LocalizedAniLink
                    fade
                    key="new"
                    to="/ingest/options"
                    style={{ alignContent: "center" }}
                  >
                    <Button type="primary">
                      {_thatIntl.formatMessage({ id: "retry" })}
                    </Button>
                  </LocalizedAniLink>
                </Col>
                <Col span={20} offset={2}>
                  <img alt="KO" src={error} />
                </Col>
              </div>
            )}

            {/* Status OK  /////////////////////////////////////////////////////////////////////////////////////// */}
            {this.state.status === "INGEST_STATUS_OK" && (
              <div>
                <Col span={18} offset={4}>
                  <h1>
                    {_thatIntl.formatMessage({
                      id: "new-ingestion-result-ok-status-header",
                    })}
                  </h1>
                  <p>
                    {_thatIntl.formatMessage({
                      id: "new-ingestion-result-ok-status-subheader",
                    })}
                  </p>
                  <LocalizedAniLink key="new" to={toUrl}>
                    <Button type="primary">
                      {_thatIntl.formatMessage({ id: "see-detail" })}
                    </Button>
                  </LocalizedAniLink>
                </Col>
                <Col span={20} offset={2}>
                  <img alt="OK" src={success} />
                </Col>
              </div>
            )}
          </Row>
        </div>
      </Template>
    )
  }
}

export default injectIntl(withAuth(IngestCreated))
